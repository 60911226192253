<template>
  <div class="invoicesContent">
    <div class="title">{{$t('font-trade-module-v1-delete-invoice')}}</div>
    <!-- search -->
    <div class="searchArea" style="margin: 16px 0px">
      <div>
        <el-input
          v-model="searchName"
          :placeholder="$t('font-trade-module-v1-invoice-intro')"
          @keydown.enter.native="getDataList"
        ></el-input>
      </div>
      <div class="searchArea">
        <span>{{$t('label.brief.payments')}}</span>
        <el-input v-model="totalMoneyS" placeholder=""></el-input>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      ref="productsystemTable"
      :data="dataList"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="88"> </el-table-column>
      <el-table-column
        v-show="index < headList.length - 1"
        v-for="(item, index) in headList"
        :key="index"
        :label="item.label"
      >
        <template slot-scope="scope">
          <div v-if="item.filedId === 'moneyDetail'">
            <el-input
              v-model="scope.row[item.filedId]"
              placeholder=""
              @input="changeDetail(scope.row)"
            ></el-input>
          </div>
          <div v-else-if="item.filedId === 'due_date'">
            {{ scope.row[item.filedId] | datetimeFormat(countryCode) }}
          </div>
          <div v-else>
            {{ scope.row[item.filedId] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 总计 -->
    <div class="totalDesc">
      <!-- 发票回款总计 -->
      <span class="desc">{{$t('font-trade-module-invoice-totalMoney')}}：</span>
      <span class="totalMoney">CNY{{ totalMoney }}</span>
    </div>
  </div>
</template>
<script>
import * as tradeManagement from "./api.js";
import * as Time from "@/utils/time";
export default {
  components: {},
  props: {
    // 发票详情关联的客户id
    customId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchName: "",
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      totalMoney: 0,
      totalMoneyS: 0,
      headList: [
        { label: this.$i18n.t('front-email-payment-Invoice-number'), filedId: "name" },
        { label: this.$i18n.t('font-trade-module-v1-invoice-name'), filedId: "invoice" },
        { label: this.$i18n.t('font-trade-module-v1-invoice-status'), filedId: "status" },
        {
          label: this.$i18n.t("label.chatter.maturiyDate"),
          filedId: "due_date",
        },
        { label:  this.$i18n.t('label.multipleactivity.total'), filedId: "totalAmount" },
        { label: this.$i18n.t('font-trade-module-v1-invoice-no-price'), filedId: "tobepaidamount" },
        { label: this.$i18n.t('font-trade-module-v1-invoice-detail-price'), filedId: "moneyDetail" },
      ],
      dataList: [],
      selectDataList: [],
    };
  },
  mounted() {},
  methods: {
    changeDetail(row) {
      this.dataList.map(item=>{
        if(item.id === row.id){
          item.moneyDetailSecond=item.moneyDetail
        }
      })
      this.totalMoney = 0;
      this.totalMoneyS = 0;
      this.selectDataList.map((item) => {
        this.totalMoney += Number(item.moneyDetail);
        this.totalMoneyS += Number(item.moneyDetail);
      });
    },
    // 获取数据
    getDataList() {
      this.dataList=[]
      tradeManagement
        .showInvoices({ keyword: this.searchName, id: this.customId })
        .then((res) => {
          if (res.data) {
            res.data.forEach((element) => {
              element.moneyDetail = "";
              element.moneyDetailSecond = ''
            });
            res.data.forEach((item) => {
              if (item.tobepaidamount.indexOf("CNY") !== -1) {
                let str1 = item.tobepaidamount;
                str1 = str1.replace(/,/g, "");
                str1 = str1.split(" ")[1];
                if (Number(str1) > 0) {
                  this.dataList.push(item);
                }
              } else {
                if (Number(item.tobepaidamount) > 0) {
                  this.dataList.push(item);
                }
              }
            });
          }
        });
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      let array = []
      selection.forEach(ele=>{
        array.push(ele.id)
      })
      this.dataList.forEach(item=>{
        if(array.indexOf(item.id)===-1){
          item.moneyDetailSecond=''
        }
      })
      this.selectDataList = selection;
      this.totalMoney = 0;
      this.totalMoneyS = 0;
      this.dataList.map((item) => {
        item.moneyDetail = "";
      });
      selection.map((item) => {
        item.moneyDetail = "";
        let str1 = "";
        if (item.tobepaidamount.indexOf("CNY") !== -1) {
          str1 = item.tobepaidamount;
          str1 = str1.replace(/,/g, "");
          str1 = str1.split(" ")[1];
        }
        item.moneyDetail = item.moneyDetailSecond === ''? Number(str1) :item.moneyDetailSecond;
        item.moneyDetail = Number(item.moneyDetail).toFixed(2)
      });
      selection.map((item) => {
        this.totalMoney += Number(item.moneyDetail);
        this.totalMoneyS += Number(item.moneyDetail);
      });
      this.$emit("invoiceList", selection);
    },
  },
  watch: {
    dataList(val) {
        val.forEach((item,index) => {
          if(item.id === this.$route.params.id){
            this.$nextTick(()=>{
              this.$refs.productsystemTable.toggleRowSelection(this.dataList[index])
            })
          }
        });
      }
    },
};
</script>
<style scoped lang="scss">
.invoicesContent {
  width: 100%;
  margin-bottom: 16px;
  .title {
    margin:16px 0px 8px 0px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    padding-left: 12px;
    margin-bottom: 10px;
    background: #F3F7FF;
    color: #080707;
    border-radius: 4px;
  }
  .searchArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      white-space: nowrap;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-right: 5px;
    }
  }
  .totalDesc {
    float: right;
    .desc {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
    .totalMoney {
      font-size: 14px;
      font-weight: bold;
      color: #006dcc;
    }
  }
}
::v-deep .el-table th > .cell {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
::v-deep .el-checkbox:last-of-type {
  margin-left: 24px;
}
</style>
